import React from "react";
import { lazyWithRetry } from "../Common/LazyRetry";
import { Navigate } from "react-router-dom";
// Authentication related pages
import Login from "../Layout/Public/Login.js";
import ForgetPwd from "../Layout/Public/ForgetPassword.js";
import ResetPassword from "../Layout/Public/ForgetPasswordPage.js";
import UnderConstruction from "../Common/UnderConstruction.js";
import GlobalApprovals from "../Pages/SecondarySales/Global Approvals";

const authRoutes = [
  // { path: "/", component: <Login />},
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/", component: <Login /> },
  { path: "/login", component: <Login /> },
];

const Warehouse = lazyWithRetry(() =>
  import(
    /*webpackChunkName:'warehouse' */ "../Pages/SystemAdmin/Warehouse/index.js"
  )
);
const Plant = lazyWithRetry(() =>
  import(/*webpackChunkName:'Plants' */ "../Pages/SystemAdmin/Plant/index.js")
);
const Modules = lazyWithRetry(() =>
  import(
    /*webpackChunkName:'Modules' */ "../Pages/SystemAdmin/Modules/index.js"
  )
);

const SystemLogs = React.lazy(() =>
  import("../Pages/SystemAdmin/SystemLogs/index.js")
);

const Dashboard = React.lazy(() =>
  import("../Pages/SystemAdmin/Dashboard/index.js")
);
const Country = React.lazy(() =>
  import("../Pages/SystemAdmin/AreaManagement/Country/index.js")
);
const Area = React.lazy(() =>
  import("../Pages/SystemAdmin/AreaManagement/Area/index.js")
);
const SubLocality = React.lazy(() =>
  import("../Pages/SystemAdmin/AreaManagement/SubLocality/index.js")
);
const Leadsource = React.lazy(() =>
  import("../Pages/SystemAdmin/LeadSources/index.js")
);
const Projectnature = React.lazy(() =>
  import("../Pages/SystemAdmin/ProjectNature/index.js")
);
const Authorities = React.lazy(() =>
  import("../Pages/SystemAdmin/Authorities/index.js")
);
const Fundingagencies = React.lazy(() =>
  import("../Pages/SystemAdmin/FundingAgencies/index.js")
);
const Projectsegments = React.lazy(() =>
  import("../Pages/SystemAdmin/ProjectSegments/index.js")
);
const Remarks = React.lazy(() =>
  import("../Pages/SystemAdmin/Remarks/index.js")
);

const Status = React.lazy(() => import("../Pages/SystemAdmin/Status/index.js"));
const Interactiontypes = React.lazy(() =>
  import("../Pages/SystemAdmin/InteractionTypes/index.js")
);

const SubZone = React.lazy(() =>
  import("../Pages/SystemAdmin/AreaManagement/SubZone/index.js")
);
const Zone = React.lazy(() =>
  import("../Pages/SystemAdmin/AreaManagement/Zone/index.js")
);
const City = React.lazy(() =>
  import("../Pages/SystemAdmin/AreaManagement/City/index.js")
);
const Region = React.lazy(() =>
  import("../Pages/SystemAdmin/AreaManagement/Region/index.js")
);
const Brand = React.lazy(() =>
  import("../Pages/SystemAdmin/BrandManagemant/Brand/index.js")
);
const BrandType = React.lazy(() =>
  import("../Pages/SystemAdmin/BrandManagemant/BrandType/index.js")
);
const Employee = React.lazy(() =>
  import("../Pages/SystemAdmin/UserManagement/Employee/index.js")
);
const Department = React.lazy(() =>
  import("../Pages/SystemAdmin/CompanySetup/Department/index.js")
);
const SubDepartment = React.lazy(() =>
  import("../Pages/SystemAdmin/CompanySetup/subDepartment/index.js")
);
const Designation = React.lazy(() =>
  import("../Pages/SystemAdmin/CompanySetup/Designation/index.js")
);
const Grades = React.lazy(() =>
  import("../Pages/SystemAdmin/CompanySetup/Grade/index.js")
);
const CompanyInformation = React.lazy(() =>
  import("../Pages/SystemAdmin/CompanySetup/CompanyInformation/index.js")
);
const CompanyStructure = React.lazy(() =>
  import("../Pages/SystemAdmin/CompanySetup/CompanyStructure/index.js")
);
const Channels = React.lazy(() =>
  import("../Pages/SystemAdmin/OutletManagement/Channels/index.js")
);
const Outlet = React.lazy(() =>
  import("../Pages/SystemAdmin/OutletManagement/Outlet/index.js")
);
const Distributor = React.lazy(() =>
  import("../Pages/SystemAdmin/OutletManagement/Distributor/index.js")
);
const RoleAndPermission = React.lazy(() =>
  import("../Pages/SystemAdmin/UserManagement/RolesAndPermission/index.js")
);
const Users = React.lazy(() =>
  import("../Pages/SystemAdmin/UserManagement/Users/index.js")
);
const UsersGroup = React.lazy(() =>
  import("../Pages/SystemAdmin/UserManagement/UsersGroup/index.js")
);
const Product = React.lazy(() =>
  import("../Pages/SystemAdmin//ProductManagement/Product/index.js")
);
const ProductTypes = React.lazy(() =>
  import("../Pages/SystemAdmin//ProductManagement/ProductTypes/index.js")
);

const Notifications = React.lazy(() =>
  import("../Pages/SystemAdmin/Notifications/index.js")
);
const GISDashboard = React.lazy(() =>
  import("../Pages/SystemAdmin/OutletManagement/GIS_outlet/index.js")
);
// const BulkUpdate = React.lazy(() =>
//   import("../Pages/SystemAdmin/BulkUpdate/index.js")
// );
const BulkUpdate = React.lazy(() =>
  import("../Pages/SystemAdmin/BulkUpdate/index.js")
);
const FlowData = React.lazy(() =>
  import("../Pages/SystemAdmin/FlowData/index.js")
);

const TradeAgreement = lazyWithRetry(() =>
  import(
    /*webpackChunkName:'Price Agreement' */ "../Pages/SystemAdmin/SalesManagement/PriceAgreement/index"
  )
);
const DocType = React.lazy(() =>
  import("../Pages/SystemAdmin/WorkFlowManagment/DocType/index.js")
);

const SalesGroup = React.lazy(() =>
  import("../Pages/SystemAdmin/SalesManagement/Sales Group/index")
);

const ExortImportWidget = React.lazy(() =>
  import("../Pages/SystemAdmin/ImportExportWidget/index.js")
);

const AssignOutlet = lazyWithRetry(() =>
  import("../Pages/SystemAdmin/OutletManagement/AssignOutlet/index.js")
);

const ProductLaunch = lazyWithRetry(() =>
  import("../Pages/SystemAdmin/ProductManagement/ProductLaunch/index")
);

const InternalComplaint = React.lazy(() =>
  import(
    "../Pages/SecondarySales/ComplaintManagemant/InternalComplaint/index.js"
  )
);
const AllComplaintDraft = React.lazy(() =>
  import("../Pages/SecondarySales/ComplaintManagemant/DraftComplaint/index.js")
);
const SalesPlanning = React.lazy(() =>
  import("../Pages/SecondarySales/DOManagement/SalesPlanning/index")
);
const ExternalComplaint = React.lazy(() =>
  import(
    "../Pages/SecondarySales/ComplaintManagemant/ExternalComplaint.js/index.js"
  )
);
// const SSPExpense = React.lazy(() =>
//   import("../Pages/SecondarySales/ExpenseManagemant/SSPExpense/index.js")
// );
// const CreditManagemant = React.lazy(() =>
//   import("../Pages/SecondarySales/CreditManagemant/index.js")
// );

const MarketInterSurvey = React.lazy(() =>
  import("../Pages/SecondarySales/MarketInterSurvey/index.js")
);
const MarketIntelNERAnalytics = React.lazy(() =>
  import("../Pages/SecondarySales/MarketInterSurvey/MarketIntelNERAnalytics/index")
);
const Reports = React.lazy(() =>
  import("../Pages/SecondarySales/Reports/index.js")
);
const MarketInterSurveyDraft = React.lazy(() =>
  import("../Pages/SecondarySales/MarketInterSurveyDraft/index.js")
);

const PerformanceManagemant = React.lazy(() =>
  import("../Pages/SecondarySales/PerformanceManagemant/index.js")
);
const PerformanceEmployees = React.lazy(() =>
  import("../Pages/SecondarySales/Performance/Employees/index.js")
);
const PerformanceDistributors = React.lazy(() =>
  import("../Pages/SecondarySales/Performance/Distributors/index.js")
);
const PerformanceOutlets = React.lazy(() =>
  import("../Pages/SecondarySales/Performance/Outlets/index.js")
);

const AssignPriorities = React.lazy(() =>
  import("../Pages/SecondarySales/MarketVisits/AssignPriorities/index.js")
);
const MarketVisitHistory = React.lazy(() =>
  import("../Pages/SecondarySales/MarketVisits/MarketVisitHistory")
);
const Journeys = React.lazy(() =>
  import("../Pages/SecondarySales/MarketVisits/Journeys/index.js")
);
const MarketVisitDetails = React.lazy(() =>
  import("../Pages/SecondarySales/MarketVisits/MarketVisitDetails")
);
const AreaViolations = React.lazy(() =>
  import("../Pages/SecondarySales/AreaViolations/index.js")
);
const AreaViolationsDrafts = React.lazy(() =>
  import("../Pages/SecondarySales/AreaViolationsDraft/index.js")
);
const CreditAssignment = React.lazy(() =>
  import("../Pages/SecondarySales/CreditManagemant/CreditAssignment/index.js")
);

const CreditCollection = React.lazy(() =>
  import("../Pages/SecondarySales/CreditManagemant/CreditCollection/index.js")
);
const CheckPayments = React.lazy(() =>
  import("../Pages/SecondarySales/CreditManagemant/CheckPayments/index.js")
);
const JourneyLogs = React.lazy(() =>
  import("../Pages/SecondarySales/MarketVisits/JourneysLogs/index.js")
);

const AllOutlet = React.lazy(() =>
  import("../Pages/SecondarySales/SalesUniverse/AllOutlet/index.js")
);
const PendingOrder = React.lazy(() =>
  import("../Pages/SecondarySales/OrderManagemant/PendingOrder/index.js")
);
const OutletDetails = React.lazy(() =>
  import("../Pages/SecondarySales/SalesUniverse/OutletDetails/index.js")
);
const OutletDraft = React.lazy(() =>
  import("../Pages/SecondarySales/SalesUniverse/OutletDraft/index.js")
);
const OrderLogs = React.lazy(() =>
  import("../Pages/SecondarySales/OrderManagemant/OrderLogs/index.js")
);
const AssignDo = React.lazy(() =>
  import("../Pages/SecondarySales/OrderManagemant/AssignDo/index.js")
);
const AddNewTarget = React.lazy(() =>
  import("../Pages/SecondarySales/TargetAssignment/AddNewTarget")
);
const DoList = React.lazy(() =>
  import("../Pages/SecondarySales/DOManagement/DoList/index")
);
const DORequest = React.lazy(() =>
  import("../Pages/SecondarySales/DOManagement/DORequest/index")
);
const SalesUniverse = React.lazy(() =>
  import("../Pages/SecondarySales/DOManagement/SalesPlanning/index.js")
);
const Dispatch = React.lazy(() =>
  import("../Pages/SecondarySales/OrderManagemant/DispatchMangement/Dispatch")
);

const PendingOutlet = React.lazy(() =>
  import("../Pages/SecondarySales/SalesUniverse/NewPendingOutlet.js/index.js")
);
const VerifiedOutlet = React.lazy(() =>
  import("../Pages/SecondarySales/SalesUniverse/VerifiedOutlet/index.js")
);
const GISOverview = React.lazy(() =>
  import("../Pages/SecondarySales/SalesUniverse/GISOverview/index")
);
const Transaction = React.lazy(() =>
  import("../Pages/SecondarySales/ExpenseManagement/Expense/index.js")
);
const Category = React.lazy(() =>
  import("../Pages/SecondarySales/ExpenseManagement/Category/index.js")
);

const Subcategory = React.lazy(() =>
  import("../Pages/SecondarySales/ExpenseManagement/SubCategory/index.js")
);
const WorkFlow = React.lazy(() =>
  import("../Pages/SecondarySales/WorkFlow/index.js")
);
const RequestExternalDo = React.lazy(() =>
  import("../Pages/SecondarySales/DOManagement/RequestExternalDo/index.js")
);
const Profile = React.lazy(() => import("../Pages/ProfilePage.js"));
const UnlockUser = React.lazy(() =>
  import("../Pages/SystemAdmin/UserManagement/UnlockUser/index.js")
);
const TargetAssignment = React.lazy(() =>
  import("../Pages/SecondarySales/TargetAssignment/index")
);
const ExistingTargetAssign = React.lazy(() =>
  import("../Pages/SecondarySales/TargetAssignment/ExistingTargetAssign/index")
);
const ProspectManagement = React.lazy(() =>
  import("../Pages/InstitutionalSales/ProspectManagement/index")
);
// const RebatePromo = React.lazy(() =>
//   import("../Pages/SecondarySales/RebatePromo/Rebate/index.js")
// );
const Rebate = React.lazy(() =>
  import("../Pages/SecondarySales/RebatePromo/Rebate/index.js")
);

const TradingOffer = React.lazy(() =>
  import("../Pages/SecondarySales/RebatePromo/TradingOffer/index.js")
);
const LeadManagement = React.lazy(() =>
  import("../Pages/InstitutionalSales/LeadManagement/index.js")
);
const CustomerManagement = React.lazy(() =>
  import("../Pages/InstitutionalSales/CustomerManagement/index.js")
);
const CsManagement = React.lazy(() =>
  import("../Pages/InstitutionalSales/3 C's Management/index.js")
);
const VisualisationAndAnalyticsReports = React.lazy(() =>
  import("../Pages/VisualisationAndAnalytics/Reports/index.js")
);
const userRoute = [
  //authencation page
  { path: "dashboard", component: <Dashboard /> },
  { path: "country", component: <Country /> },
  { path: "area", component: <Area /> },
  { path: "subLocality", component: <SubLocality /> },
  { path: "subZone", component: <SubZone /> },
  { path: "Zone", component: <Zone /> },
  { path: "City", component: <City /> },
  { path: "Region", component: <Region /> },
  { path: "brands", component: <Brand /> },
  { path: "brandTypes", component: <BrandType /> },
  { path: "modules", component: <Modules /> },
  { path: "logs", component: <SystemLogs /> },
  { path: "department", component: <Department /> },
  { path: "subDepartment", component: <SubDepartment /> },
  { path: "designation", component: <Designation /> },
  { path: "grades", component: <Grades /> },
  { path: "companyInformation", component: <CompanyInformation /> },
  { path: "companyStructure", component: <CompanyStructure /> },
  { path: "channels", component: <Channels /> },
  { path: "outlet", component: <Outlet /> },
  { path: "distributor", component: <Distributor /> },
  { path: "role", component: <RoleAndPermission /> },
  { path: "user", component: <Users /> },
  { path: "userGroup", component: <UsersGroup /> },
  { path: "plants", component: <Plant /> },
  { path: "product", component: <Product /> },
  { path: "productLaunch", component: <ProductLaunch /> },
  { path: "productTypes", component: <ProductTypes /> },
  { path: "warehouse", component: <Warehouse /> },
  { path: "notifications", component: <Notifications /> },
  { path: "bulkupdate", component: <BulkUpdate /> },
  { path: "employees", component: <Employee /> },
  { path: "unlockUser", component: <UnlockUser /> },
  { path: "flowData", component: <FlowData /> },
  { path: "GISOutlet", component: <GISDashboard /> },
  { path: "importExportWidget", component: <ExortImportWidget /> },
  { path: "assignOutlet", component: <AssignOutlet /> },
  { path: "tradeagreement", component: <TradeAgreement /> },
  { path: "salesGroup", component: <SalesGroup /> },
  { path: "leadsource", component: <Leadsource /> },
  { path: "projectnature", component: <Projectnature /> },
  { path: "authorities", component: <Authorities /> },
  { path: "fundingagencies", component: <Fundingagencies /> },
  { path: "projectsegments", component: <Projectsegments /> },
  { path: "remarks", component: <Remarks /> },
  { path: "interactiontypes", component: <Interactiontypes /> },
  { path: "status", component: <Status /> },

  { path: "doctype", component: <DocType /> },
  { path: "workflow", component: <WorkFlow /> },
  {
    path: "/systemAdmin",
    exact: true,
    component: () => <Navigate to="/home" />,
  },
];
const ProtectedButCommon = [{ path: "profile", component: <Profile /> }];
const SSPRoutes = [
  //authencation page
  { path: "dashboard", component: <UnderConstruction /> },
  // { path: "sspcountry", component: <SSPCountry /> },
  // { path: "sspcity", component: <SSPCity /> },
  // { path: "sspregion", component: <SSPRegion /> },
  { path: "allComplaintDraft", component: <AllComplaintDraft /> },
  { path: "internalComplaint", component: <InternalComplaint /> },
  { path: "externalComplaint", component: <ExternalComplaint /> },

  { path: "category", component: <Category /> },
  { path: "subcategory", component: <Subcategory /> },
  { path: "transaction", component: <Transaction /> },

  // { path: "sspcredit", component: <CreditManagemant /> },
  // { path: "assigned", component: <AssignedTargets /> },
  { path: "marketIntelDraft", component: <MarketInterSurveyDraft /> },
  { path: "marketIntel", component: <MarketInterSurvey /> },
  { path: "marketIntelNERAnalytics", component: <MarketIntelNERAnalytics /> },

  { path: "reports", component: <Reports /> },

  { path: "creditAssignment", component: <CreditAssignment /> },
  { path: "creditCollection", component: <CreditCollection /> },
  { path: "chequePayments", component: <CheckPayments /> },
  { path: "journeys", component: <Journeys /> },
  { path: "marketHistory", component: <MarketVisitHistory /> },
  { path: "journeylogs", component: <JourneyLogs /> },
  { path: "marketVisit", component: <MarketVisitDetails /> },
  { path: "assignPriorities", component: <AssignPriorities /> },
  { path: "pendingOrder", component: <PendingOrder /> },
  { path: "orderLogs", component: <OrderLogs /> },
  { path: "assignDo", component: <AssignDo /> },
  { path: "AddNewTarget", component: <AddNewTarget /> },
  { path: "doList", component: <DoList /> },
  { path: "doRequest", component: <DORequest /> },
  { path: "globalApprovals", component: <GlobalApprovals /> },
  { path: "salesPlanning", component: <SalesPlanning /> },
  // { path: "DO_Management", component: <DOMangement /> },
  { path: "dispatchManagement", component: <Dispatch /> },
  { path: "violations", component: <AreaViolations /> },
  { path: "violationsDrafts", component: <AreaViolationsDrafts /> },
  { path: "performance", component: <PerformanceManagemant /> },
  { path: "employees", component: <PerformanceEmployees /> },
  { path: "distributors", component: <PerformanceDistributors /> },
  { path: "outlet", component: <PerformanceOutlets /> },

  // { path: "rebate", component: <RebatePromo /> },
  { path: "allOutlet", component: <AllOutlet /> },
  { path: "OutletDraft", component: <OutletDraft /> },

  { path: "verifiedOutlet", component: <VerifiedOutlet /> },
  { path: "pendingOutlet", component: <PendingOutlet /> },
  { path: "gisOverview", component: <GISOverview /> },
  { path: "requestExternalDo", component: <RequestExternalDo /> },
  // {path:"requestExternalDo",component:<RequestExternalDo />},
  { path: "targetAssignment", component: <TargetAssignment /> },
  { path: "targetAssignmentExisting", component: <ExistingTargetAssign /> },
  { path: "requestExternalDo", component: <RequestExternalDo /> },
  { path: "rebate", component: <Rebate /> },
  { path: "tradingOffer", component: <TradingOffer /> },

  {
    path: "/secondarySales",
    exact: true,
    component: () => <Navigate to="/home" />,
  },
];
const ISPRoutes = [
  //authencation page
  { path: "dashboard", component: <UnderConstruction /> },
  { path: "leadManagement", component: <LeadManagement /> },
  { path: "customerManagement", component: <CustomerManagement /> },
  { path: "3C'sManagement", component: <CsManagement /> },
  { path: "prospectManagement", component: <ProspectManagement /> },

  {
    path: "/institutionalSales",
    exact: true,
    component: () => <Navigate to="/home" />,
  },
];
const visualisationAnalytics = [
  //authencation page
  { path: "dashboard", component: <UnderConstruction /> },
  { path: "reports", component: <VisualisationAndAnalyticsReports /> },
  // { path: "leadManagement", component: <LeadManagement /> },
  // { path: "customerManagement", component: <CustomerManagement /> },
  // { path: "3C'sManagement", component: <CsManagement /> },
  // { path: "prospectManagement", component: <ProspectManagement /> },

  {
    path: "/visualisation&analytics",
    exact: true,
    component: () => <Navigate to="/home" />,
  },
];
export { userRoute, authRoutes, SSPRoutes, ISPRoutes, ProtectedButCommon ,visualisationAnalytics };
