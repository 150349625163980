import React, { useEffect, useState } from "react";
import { Link, Navigate, redirect, useNavigate } from "react-router-dom";
import bgImg from "../../Assets/images/auth-bg2.jpg";

import institutional from "../../Assets/images/icons/InstitutionalSales.svg";
import secondary from "../../Assets/images/icons/SecondarySales.svg";
import system from "../../Assets/images/icons/SystemAdmin.svg";
import NavbarHeader from "../../Layout/Protected/NavbarHeader";
import { useSelector } from "react-redux";

const LandingPage = () => {
  // const { featuresArray } = useSelector((state) => state.featureSlice);
  const featuresArray = {
    modules_list: [1, 2, 3 ,4],
  };
  console.log(featuresArray, "feature array");
  const navigate = useNavigate();
  useEffect(() => {
    const auth = window.localStorage.getItem("token");
    if (!auth) {
      navigate("/");
    }
  }, []);

  const [search, setSearch] = useState("");
  const handleChange = (e) => {
    setSearch(e.target.value);
    console.log(search);
  };

  return (
    <>
      <NavbarHeader homepage={true} />
      <div className="page-content">
        <div className="mb-5">
          <div className="my-3" style={{ fontWeight: "500", fontSize: "16px" }}>
            Switch to
          </div>

          <div
            className="d-flex flex-wrap flex-row"
            style={{ fontSize: "14px", fontWeight: "400" }}
          >
            {featuresArray?.modules_list &&
            featuresArray?.modules_list?.includes(1) ? (
              <Link
                to="/systemAdmin/dashboard"
                className="d-flex flex-column align-items-center mx-4"
                // onClick={() => dispatch(setModuleName(0))}
              >
                <div
                  className="bg-primary d-flex flex-column align-items-center justify-content-center mb-1"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "15px",
                  }}
                >
                  <img alt="" src={system} />
                </div>
                <div className="text-center">System Admin</div>
              </Link>
            ) : null}
            {featuresArray?.modules_list &&
            featuresArray?.modules_list.includes(2) ? (
              <Link
                to="/secondarySales/dashboard"
                className="d-flex flex-column align-items-center mx-4"
                // onClick={() => dispatch(setModuleName(1))}
              >
                <div
                  className="bg-primary d-flex flex-column align-items-center justify-content-center mb-1"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "15px",
                  }}
                >
                  <img alt="" src={secondary} />
                </div>
                <div>Secondary Sales</div>
              </Link>
            ) : null}

            {featuresArray?.modules_list &&
            featuresArray?.modules_list.includes(3) ? (
              <Link
                to="/institutionalSales/dashboard"
                className="d-flex flex-column align-items-center mx-4"
                // onClick={() => dispatch(setModuleName(2))}
              >
                <div
                  className="bg-primary d-flex flex-column align-items-center justify-content-center mb-1"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "14px",
                    left: 14,
                  }}
                >
                  <img alt="" src={institutional} />
                </div>
                <p className="font-size-14 fw-400">Institutional Sales</p>
              </Link>
            ) : null} 
                {featuresArray?.modules_list &&
            featuresArray?.modules_list.includes(4) ? (
              <Link
                to="/visualisation&analytics/dashboard"
                className="d-flex flex-column align-items-center mx-4"
                // onClick={() => dispatch(setModuleName(2))}
              >
                <div
                  className="bg-primary d-flex flex-column align-items-center justify-content-center mb-1"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "14px",
                    left: 14,
                  }}
                >
                  <img alt="" src={institutional} />
                </div>
                <p className="font-size-14 fw-400">Visualisation & Analytics</p>
              </Link>
            ) : null}
          </div>
          <div className="mt-5 d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "500", fontSize: "16px" }}>
                Recent
              </span>
            </div>
            <div>
              <label htmlFor="" className="" style={{ fontSize: "14px" }}>
                Search: &nbsp;
              </label>
              <input type="search" value={search} onChange={handleChange} />
            </div>
          </div>
          <hr />
          {/* <div>
        <div className="row">
          <div className="col-6">
            <ul
              className="list-unstyled "
              style={{ fontWeight: "500", fontSize: "16px" }}
            >
              <li className="m-3 ">
                <AiOutlineUser className="me-3 lead" />
                <span>user</span>
              </li>
              <li className="m-3 ">
                <AiOutlineMinusSquare className="me-3 lead" />
                hierarchy
              </li>
              <li className="m-3 ">
                <BiCube className="me-3 lead" />
                product
              </li>
              <li className="m-3 ">
                <BiAbacus className="me-3 lead" />
                user
              </li>
              <li className="m-3 ">
                <BiAbacus className="me-3 lead" />
                user
              </li>
              <li className="m-3 ">
                <BiAbacus className="me-3 lead" />
                user
              </li>{" "}
              <li className="m-3 ">
                <BiAbacus className="me-3 lead" />
                user
              </li>{" "}
              <li className="m-3 ">
                <BiAbacus className="me-3 lead" />
                user
              </li>
              <li className="m-3 ">
                <BiAbacus className="me-3 lead" />
                user
              </li>
            </ul>
          </div>
          <div className="col-6">
            <ul
              className="list-unstyled responsive"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "25px",
              }}
            >
              <li className="m-3 ">You opened it one day ago</li>
              <li className="m-3 ">You opened it one day ago</li>
              <li className="m-3 ">You opened it one day ago</li>
              <li className="m-3 ">You opened it one day ago</li>
              <li className="m-3 ">You opened it one day ago</li>
              <li className="m-3 ">You opened it one day ago</li>
              <li className="m-3 ">You opened it one day ago</li>
              <li className="m-3 ">You opened it one day ago</li>
              <li className="m-3 ">You opened it one day ago</li>
            </ul>
          </div>
        </div>
      </div> */}
        </div>
      </div>
    </>

    // <>
    //   <div>
    //     <Overlay className="bg-overlay bg-primary "></Overlay>
    //     <Content
    //       className="bg-dark border d-flex  align-items-end"
    //       style={{}}
    //       xs="12"
    //     >
    //       <div style={{ zIndex: 9 }} className="p-4">
    //         <p className="h3 text-white">Welcome Back!</p>

    //         <p className="h1 text-white">{user || "User"}</p>
    //       </div>
    //     </Content>{" "}
    //   </div>
    //   <h4 className="px-2 pt-2">Applications</h4>
    //   <Row className="p-3 g-2">
    //     <Col sm="4" lg="3">
    //       <Link to="/dashboard" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="home" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Dashboard</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/department" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="grid" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Department</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/designation" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="grid" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Designation</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/area-view" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="map" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Area View</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/regions" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="map" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Regions</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/cities" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="map" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">City</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/zones" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="map" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Zone</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/sub-zone" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="map" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Sub Zone</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/users" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="grid" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Users</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/employees" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="grid" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Employees</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/outlets" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="shopping-bag" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">OutLets</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/notifications" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="bell" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Notifications</h4>{" "}
    //       </Link>
    //     </Col>

    //     <Col sm="4" lg="3">
    //       <Link to="/dashboard" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="clipboard" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Hierarchy</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/dashboard" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="file" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Modules</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/dashboard" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="shopping-bag" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Products</h4>
    //       </Link>
    //     </Col>
    //     <Col sm="4" lg="3">
    //       <Link to="/dashboard" className="d-flex flex-inline m-3">
    //         <FeatherIcon icon="activity" />
    //         <h4 className="font-size-16 mt-1 ms-2 ">Market Intelligence</h4>
    //       </Link>
    //     </Col>
    //   </Row>{" "}
    //   <h4 className="px-2 pt-2">Notifications</h4>
    // </>
  );
};

export default LandingPage;
